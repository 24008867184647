import useQuery from '@engined/client/hooks/useQuery.js';
import CreateTimeEntryDialog from '@asaprint/asap/components/dialogs/CreateTimeEntryDialog.js';
import DisplayDuration from '@asaprint/asap/components/DisplayDuration.js';
import PageHeader from '@asaprint/asap/components/PageHeader.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import {
  GraphQLReactTable as TGraphQLReactTable,
  GraphQLReactTableProps,
} from '@asaprint/asap/components/ReactTable.js';
import CenteredCell from '@asaprint/asap/components/tables/CenteredCell.js';
import DateTimeCell from '@asaprint/asap/components/tables/DateTimeCell.js';
import DateTimeFilter from '@asaprint/asap/components/tables/DateTimeFilter.js';
import UserCell from '@asaprint/asap/components/tables/UserCell.js';
import UserFilter from '@asaprint/asap/components/tables/UserFilter.js';
import TimeEntryEditButton from '@asaprint/asap/components/TimeEntryEditButton.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import authorized from '@asaprint/asap/decorators/authorized.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import { DASHBOARD_ROUTE, ORDERS_RECEIVED_ROUTE, ORDERS_RECEIVED_SHOW_ROUTE } from '@asaprint/asap/routes.js';
import {
  TimeEntriesOrderReceived_Load,
  TimeEntriesOrderReceived_LoadOrderReceived,
} from '@asaprint/asap/routes/__authenticated/time-entries/$id.graphql';
import {
  OrderReceivedField_OrderFragment,
  OrderReceivedFilter,
  TimeEntriesOrderReceived_LoadOrderReceivedQuery,
  TimeEntriesOrderReceived_LoadOrderReceivedQueryVariables,
  TimeEntriesOrderReceived_LoadQuery,
  TimeEntriesOrderReceived_TimeEntryFragment,
} from '@asaprint/asap/schema.client.types.js';
import { hasPermission, Permission } from '@asaprint/common/access.js';
import {
  ORDER_RECEIVED_PHASE_NAMES,
  OrderReceivedPhase,
  phaseOptions,
  phaseSx,
} from '@asaprint/common/constants/OrderReceived.js';
import { TIME_ENTRY_POSITION_NAMES } from '@asaprint/common/constants/TimeEntry.js';
import { toOption } from '@asaprint/common/helpers/OrderReceived.js';
import { Option as AutocompleteOption } from '@engined/client/components/forms/fields/AutocompleteField.js';
import Loading from '@engined/client/components/Loading.js';
import SelectFilter, { Option } from '@engined/client/components/table/SelectFilter.js';
import useDialog from '@engined/client/hooks/useDialog.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { url } from '@engined/core/services/routes.js';
import { faUserClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Add, ArrowBack } from '@mui/icons-material';
import { Box, Button, Chip, Link } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useParams, useRouteLoaderData } from 'react-router';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Column, TableState } from 'react-table';

const GraphQLReactTable = TGraphQLReactTable as unknown as React.NamedExoticComponent<
  GraphQLReactTableProps<TimeEntriesOrderReceived_TimeEntryFragment>
>;
const positionOptions: Option[] = Object.keys(TIME_ENTRY_POSITION_NAMES).map((k) => ({
  value: k.toString(),
  label: TIME_ENTRY_POSITION_NAMES[k],
}));

interface OwnProps {}

type Props = OwnProps;

const emptyArray = [];
const defaultSortBy: TableState['sortBy'] = [{ id: 'endAt', desc: false }];

const mapData = (data: TimeEntriesOrderReceived_LoadQuery) => data?.timeEntries?.edges.map((e) => e.node) || emptyArray;
const mapCount = (data: TimeEntriesOrderReceived_LoadQuery) => data?.timeEntries?.pageInfo.count || 0;

const refetchQueries = ['TimeEntriesOrderReceived_Load'];

const OrderReceivedId: React.FunctionComponent<Props> = () => {
  const params = useParams();
  const orderReceivedId = params.id as string;
  const { data, loading, error } = useQuery<
    TimeEntriesOrderReceived_LoadOrderReceivedQuery,
    TimeEntriesOrderReceived_LoadOrderReceivedQueryVariables
  >(TimeEntriesOrderReceived_LoadOrderReceived, {
    variables: {
      id: orderReceivedId,
    },
  });
  const { requestUser } = useAuth();
  const canManageTimeEntries = hasPermission(requestUser.permissions as Permission[], Permission.TimeEntriesManage);
  const canManageTimeEntriesAll = hasPermission(
    requestUser.permissions as Permission[],
    Permission.TimeEntriesManageAll,
  );

  const orderReceived = data?.orderReceived;

  const columns: Column<TimeEntriesOrderReceived_TimeEntryFragment>[] = useMemo(
    (): Column<TimeEntriesOrderReceived_TimeEntryFragment>[] => [
      {
        Header: 'Akcie',
        accessor: 'id',
        disableFilters: true,
        disableSortBy: true,
        width: 20,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value }, row: { original } }) => (
          <CenteredCell>
            {(canManageTimeEntriesAll || (canManageTimeEntries && original.user.id === requestUser.id)) && (
              <TimeEntryEditButton timeEntryId={value} refetchQueries={refetchQueries} />
            )}
          </CenteredCell>
        ),
      },
      {
        Header: 'Začiatok',
        accessor: 'startAt',
        Filter: DateTimeFilter,
        Cell: DateTimeCell,
        maxWidth: 80,
        width: 80,
      },
      {
        Header: 'Koniec',
        accessor: 'endAt',
        Filter: DateTimeFilter,
        // eslint-disable-next-line react/display-name
        Cell: DateTimeCell,
        maxWidth: 80,
        width: 80,
      },
      {
        Header: 'Používateľ',
        accessor: 'user',
        width: 80,
        Cell: UserCell,
        Filter: UserFilter,
        sortByToGraphQLVariable: () => 'user.fullName',
      },
      {
        Header: 'Prac. pozícia',
        accessor: 'position',
        width: 30,
        // eslint-disable-next-line react/display-name
        Filter: (props) => <SelectFilter {...props} options={positionOptions} />,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value } }) => <CenteredCell>{TIME_ENTRY_POSITION_NAMES?.[value] ?? '-'}</CenteredCell>,
        filterToGraphQLVariable: (value) => ({ eq: parseInt(value) }),
      },
      {
        Header: 'Čas',
        accessor: 'duration',
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value } }) => (
          <CenteredCell>
            <DisplayDuration duration={value} />
          </CenteredCell>
        ),
        filterToGraphQLVariable: (value) => ({
          eq: parseFloat(value.replace(/,/g, '.')) * 3600,
        }),
        // eslint-disable-next-line react/display-name
        Footer: ({ rows, queryData }) => {
          return (
            <CenteredCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
              <DisplayDuration
                duration={(queryData as TimeEntriesOrderReceived_LoadQuery)?.timeEntries?.aggregate.duration.sum || 0}
              />
            </CenteredCell>
          );
        },
        width: 50,
      },
      {
        Header: 'Poznámka',
        accessor: 'note',
        width: 150,
      },
      {
        Header: 'Kód produktu',
        id: 'product.orderReceived.moneyNumber',
        accessor: (row) => orderReceived?.moneyNumber,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value }, row: { original } }) => (
          <CenteredCell>
            {original.product ? (
              <Link
                component={RouterLink}
                to={`${url(ORDERS_RECEIVED_SHOW_ROUTE, {
                  id: orderReceived.id.toLowerCase(),
                })}#product-${original.product.id}`}
              >
                {value}-{original.product.position}
              </Link>
            ) : (
              '-'
            )}
          </CenteredCell>
        ),
        width: 80,
        sortByToGraphQLVariable: () => ['product.orderReceived.moneyNumber', 'product.position'],
        filterToGraphQLVariable: (value, language, filter) => {
          const parts = value.split('-');
          if (parts.length > 1 && /\d+/.test(parts[1])) {
            filter.product ??= {};
            filter.product.position = { eq: parseInt(parts[1], 10) };
          }

          return {
            ilike: `%${parts[0]}%`,
          };
        },
      },
      {
        Header: 'Názov produktu',
        id: 'product.name',
        accessor: (row) => row.product?.name,
        width: 120,
      },
      {
        Header: 'Názov fázy',
        id: 'phase.name',
        accessor: (row) => row.phase?.name,
        width: 120,
      },
      {
        Header: 'Názov fázy objednávky',
        accessor: 'orderReceivedPhase',
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value } }) =>
          value && <Chip sx={{ ...phaseSx[value] }} label={ORDER_RECEIVED_PHASE_NAMES[value]} />,
        // eslint-disable-next-line react/display-name
        Filter: (props) => <SelectFilter {...props} options={phaseOptions} />,
        width: 150,
      },
    ],
    [orderReceived, canManageTimeEntries, canManageTimeEntriesAll, requestUser.id],
  );

  const modifyVariables = useCallback(
    (variables): { filter: OrderReceivedFilter } => {
      return {
        ...variables,
        filter: {
          AND: [
            {
              ...variables.filter,
            },
            {
              orderReceived: {
                id: { eq: orderReceivedId },
              },
            },
          ],
        },
      };
    },
    [orderReceivedId],
  );

  const {
    open: createTimeEntryDialogOpen,
    onOpen: createTimeEntryDialogOnOpen,
    onClose: createTimeEntryDialogOnClose,
  } = useDialog<boolean>(false);

  const orderReceivedOption = useMemo<AutocompleteOption<OrderReceivedField_OrderFragment>>(
    () => (orderReceived ? toOption(orderReceived) : null),
    [orderReceived],
  );

  return (
    <>
      <PageHeader
        title={
          <>
            <FontAwesomeIcon icon={faUserClock} /> Pracovný log objednávky: {orderReceived?.moneyNumber ?? '...'} -{' '}
            {orderReceived?.moneyAddressName ?? orderReceived?.moneyAddressContactName ?? '...'} -{' '}
            {orderReceived?.moneyName ?? '...'}
          </>
        }
        actions={
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <BackToOrderButton id={orderReceivedId} />
            {orderReceived?.phase && orderReceived?.phase !== OrderReceivedPhase.DONE && (
              <Button color="primary" variant="contained" onClick={createTimeEntryDialogOnOpen} startIcon={<Add />}>
                Nový záznam
              </Button>
            )}
          </Box>
        }
      />

      <PagePaper>
        {error || (loading && !data) ? (
          <Loading error={error} />
        ) : (
          <>
            <GraphQLReactTable
              id="timeEntries-timeEntriesOrderReceived"
              columns={columns}
              defaultSortBy={defaultSortBy}
              query={TimeEntriesOrderReceived_Load}
              mapCount={mapCount}
              mapData={mapData}
              canPrint={false}
              canXLSXExport={false}
              variables={modifyVariables}
            />
            <CreateTimeEntryDialog
              onClose={createTimeEntryDialogOnClose}
              open={createTimeEntryDialogOpen}
              orderReceived={orderReceivedOption}
              orderReceivedPhase={orderReceived?.phase}
              refetchQueries={refetchQueries}
            />
          </>
        )}
      </PagePaper>
    </>
  );
};

export default authorized(Permission.TimeEntriesRead)(OrderReceivedId);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs<'id'>) => {
  const result = await apollo.query<
    TimeEntriesOrderReceived_LoadOrderReceivedQuery,
    TimeEntriesOrderReceived_LoadOrderReceivedQueryVariables
  >({
    query: TimeEntriesOrderReceived_LoadOrderReceived,
    variables: {
      id: params.id,
    },
  });
  return result.data;
};

const BackToOrderButton: React.FC<{ id: string }> = ({ id }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Button
      variant="text"
      startIcon={<ArrowBack />}
      component={RouterLink}
      onClick={(event) => {
        if (location.state?.fromOrderReceived) {
          event.preventDefault();
          navigate(-1);
        }
      }}
      to={url(ORDERS_RECEIVED_SHOW_ROUTE, { id })}
    >
      Späť na objednávku
    </Button>
  );
};

const CurrentTimeEntriesBreadcrumb: React.FC = () => {
  const data = useRouteLoaderData(
    'routes/__authenticated/time-entries/$id',
  ) as TimeEntriesOrderReceived_LoadOrderReceivedQuery;

  const orderReceived = data?.orderReceived;

  return `Pracovný log` + (orderReceived?.moneyNumber ? ` objednávky: ${orderReceived?.moneyNumber}` : '');
};

export const handle: RouteHandle = {
  breadcrumbs: [
    { text: 'Dashboard', to: DASHBOARD_ROUTE },
    {
      text: 'Objednávky',
    },
    {
      text: 'Objednávky prijaté',
      to: ORDERS_RECEIVED_ROUTE,
    },
    {
      text: 'Detail objednávky',
      to: ({ id }) => url(ORDERS_RECEIVED_SHOW_ROUTE, { id }),
    },
    { node: <CurrentTimeEntriesBreadcrumb key="current" /> },
  ],
  meta: ({ meta, match }: MetaFunctionArgs<TimeEntriesOrderReceived_LoadOrderReceivedQuery>) => {
    const orderReceived = match?.data?.orderReceived;

    return {
      title:
        `Pracovný log` +
        (orderReceived?.moneyNumber
          ? ` objednávky: ${orderReceived?.moneyNumber} - ${
              orderReceived?.moneyAddressName ?? orderReceived?.moneyAddressContactName ?? '...'
            } - ${orderReceived?.moneyName ?? '...'}`
          : '') +
        ` | ${meta.title}`,
    };
  },
};
